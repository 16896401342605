<template>
  <div
    v-if="isNoValidation || canDisplayProduct(props.product)"
    :class="[$style.product_list_item, { [$style.is_horizontal]: isHorizontal, [$style.is_vertical]: isVertical }]"
    @click="trackProductListItemClick(product.document_id)"
  >
    <div :class="[$style.thumb, { [$style.out_of_stock]: isAllOutOfStock(product) }]">
      <AtomsConsumerLink :url="productLink" :disabled="!productLink || isDisableLink">
        <AtomsConsumerImage
          :src="`${sortedSkusByPrice[0].main_image}`"
          :alt="product.top_image_alt ? product.top_image_alt : defaultTopImageAlt(product)"
          :width="184 * 2"
          :height="245 * 2"
          :class="$style.img"
        />
        <div :class="$style.promotion_labels">
          <span v-if="isAllOutOfStock(product)" :class="$style.sold_out_label">SOLD OUT</span>
          <span v-if="product.is_general_medical_device" :class="$style.special">一般医療機器</span>
          <span v-else-if="product.is_quasi_drug" :class="$style.special">医薬部外品</span>
          <span
            v-for="label in product.promotion_labels"
            :key="label.document_id"
            :style="`background-color: ${label.background_color}; color: ${label.label_color}`"
          >
            {{ label.label_name }}
          </span>
        </div>
      </AtomsConsumerLink>
    </div>
    <AtomsConsumerLink :url="productLink" :disabled="!productLink || isDisableLink" :class="$style.product_detail">
      <div :class="$style.description_box">
        <p :class="[$style.name, $style.second_dotted_line]">{{ product.name }}</p>
        <p :class="[$style.description_text, $style.second_dotted_line]">{{ product.material }}</p>
        <div :class="$style.description_text">
          <span v-if="product.sex">{{ getSexLabel(product.sex) }}</span>
          <span v-if="product.sex && product.size_range">/</span>
          <span v-if="product.size_range">{{ product.size_range }}</span>
        </div>
        <p v-if="productColors.uniqueLabels.size > 0" :class="$style.description_text">
          {{ productColors.uniqueLabels.size }}カラー
        </p>
        <div :class="$style.price">
          <div :class="{ [$style.discount]: sortedSkusByPrice[0].discounted_price }">
            <span :class="$style.yen">&yen; </span>
            <span>
              {{ calcPrice(sortedSkusByPrice[0]).effective_reference_price_comma }}
            </span>
            <span :class="$style.tax">(参考税込)</span>
          </div>
          <AtomsConsumerButton :class="$style.bookmark" @click="(e: Event) => openFavModal(e, product)">
            <AtomsConsumerImage src="/images/icon/fav.svg" alt="お気に入り" :width="20" :height="20" />
          </AtomsConsumerButton>
        </div>
      </div>
      <AtomsConsumerButton
        :variant="buttonVariant"
        rounded
        block
        @click="openAddCartModal($event, product.document_id)"
      >
        カートに追加
      </AtomsConsumerButton>
    </AtomsConsumerLink>
  </div>
</template>

<script setup lang="ts">
import { calcPrice } from "@tential/ec-gql-schema/utils/functions/price"
import { VariantType } from "@tential/consumer-component/type"
import { PagePartsProductType } from "~/types/product"
import { SEX_ITEMS } from "~/const/product"
import { canDisplayProduct, getSiteProductPageUrl, isAllOutOfStock } from "~/utils/functions/product"
import { defineRestockNoticeModal } from "~/state"
import { useStore } from "~/stores"

defineRestockNoticeModal()

const store = useStore()

const { trackProductListItemClick } = useGtagEvent()

type Props = {
  product: PagePartsProductType
  /** リンク化しない（親コンポーネントでモーダルを出すなどの場合）(default: false) */
  isDisableLink?: boolean
  /** 商品一覧に表示しない商品も表示する(default: false) */
  isNoValidation?: boolean
  /** 横長タイプ */
  isHorizontal?: boolean
  /** 縦長タイプ */
  isVertical?: boolean
  /** ボタンスタイル */
  buttonVariant?: VariantType
}
const props = withDefaults(defineProps<Props>(), {
  isDisableLink: false,
  isNoValidation: false,
  isHorizontal: false,
  isVertical: false,
  buttonVariant: "secondary",
})

// publicProductAllSearch に合わせて、価格の安い順に並べる
const sortedSkusByPrice = computed(() => {
  return [...props.product.skus].sort((a, b) => a.price - b.price)
})

const defaultTopImageAlt = (product: PagePartsProductType): string => {
  return `${product.name}${product.material ? " / " + product.material : ""}`
}

const getSexLabel = (sexValue: string): string => {
  for (const item of SEX_ITEMS) {
    if (item.value === sexValue) return item.key
  }
  return ""
}

const productLink = getSiteProductPageUrl(props.product)

const productColors = computed(() => {
  const uniqueLabels = new Set<string>()
  const colors = new Set<string>()
  const images: string[] = []
  if (!sortedSkusByPrice.value) return { uniqueLabels, images }
  for (const sku of sortedSkusByPrice.value) {
    if (sku.color && !uniqueLabels.has(sku.color)) {
      uniqueLabels.add(sku.color)
      if (sku.color_code) colors.add(sku.color_code)
      if (sku.main_image) images.push(sku.main_image)
    }
  }
  return { uniqueLabels, images }
})

const openAddCartModal = (e: Event, productId: string) => {
  e.preventDefault()
  store.setQuickViewProductId(productId)
}

const emit = defineEmits<{
  (e: "openQuickView", $event: Event, product: PagePartsProductType): void
  (e: "openFavModal", $event: Event, product: PagePartsProductType): void
}>()

const openFavModal = (event: Event, product: PagePartsProductType) => {
  emit("openFavModal", event, product)
}
</script>

<style scoped module lang="scss">
@import "assets/scss/components/ProductListItemStyle.scss";
</style>
