<template>
  <div v-if="isSpColumn && !minScreenLg" :class="$style.item_columns">
    <PagePartsProductListItem
      v-for="product in products"
      :key="`sp-${product.document_id}`"
      :class="$style.column"
      :product="product"
      :is-horizontal="isHorizontal"
      :is-vertical="isVertical"
      :button-variant="buttonVariant"
      @open-fav-modal="openFavModal($event, product)"
    />
  </div>
  <PagePartsProductListCarousel v-else :item-length="products.length">
    <template #items>
      <PagePartsProductListItem
        v-for="product in products"
        :key="`md-${product.document_id}`"
        :class="$style.slide"
        :product="product"
        :is-horizontal="isHorizontal"
        :is-vertical="isVertical"
        :button-variant="buttonVariant"
        @open-fav-modal="openFavModal($event, product)"
      />
    </template>
    <template #spItems>
      <PagePartsProductListItem
        v-for="product in products"
        :key="`sp-${product.document_id}`"
        :class="$style.slide"
        :product="product"
        :is-horizontal="isHorizontal"
        :is-vertical="isVertical"
        :button-variant="buttonVariant"
        @open-fav-modal="openFavModal($event, product)"
      />
    </template>
  </PagePartsProductListCarousel>
  <MoleculesConsumerModal v-model="isOpenFavModal">
    <ProductSelectFav
      :product="favModalProduct"
      :favorite-list="favoriteListRef"
      :is-processing="isProcessing"
      @on-favorite="onFavoriteByProductList"
      @lift="() => (isOpenFavModal = false)"
    />
  </MoleculesConsumerModal>
</template>

<script setup lang="ts">
import { VariantType } from "@tential/consumer-component/type"
import { PagePartsProductType } from "~/types/product"
import { useFavorite } from "~/composables/useFavorite"

const { openFavModal, onFavoriteByProductList, isOpenFavModal, favModalProduct, favoriteListRef, isProcessing } =
  useFavorite()

const { width: windowWidth } = useWindowSize()
// @mixin lg と同じ値に設定
const minScreenLg = computed(() => windowWidth.value >= 1024)

withDefaults(
  defineProps<{
    products: PagePartsProductType[]
    isHorizontal?: boolean
    isVertical?: boolean
    isSpColumn?: boolean
    /** ボタンスタイル */
    buttonVariant?: VariantType
  }>(),
  {
    isHorizontal: false,
    isVertical: false,
    isSpColumn: false,
    buttonVariant: undefined,
  },
)
</script>

<style scoped module lang="scss">
.slide {
  cursor: pointer;
  flex: 0 0 70%;
  @include md {
    flex: 0 0 25%;
  }
}
.item_columns {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  .column {
    flex: auto;
  }
}
</style>
